import { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import "./index.css";

import NoPage from "@pages/NoPage";
import { AppStateProvider } from "@state/AppProvider";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  MsalProvider,
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Login from "@pages/login/Login";
import { chatTip } from "@api";
import { AbilityContext } from "@api/security/contextualPermission";
import { defineAbilityFor } from "@api/security/permissions";
import LoginTeamsStart from "@pages/login/LoginTeamsStart";
import LoginTeamsEnd from "@pages/login/LoginTeamsEnd";
import { useAtom, useAtomValue } from "jotai";
import { currentAssistantAtom, currentThemeAtom, msalInstanceAtom } from "@state/Atoms";
import { ThemeProvider } from "styled-components";
import Loading from "@components/Loading";

const LazyJotaiDevTools = lazy(() => import("@components/DevTools/jotai"));
const LazyAdminPage = lazy(() => import("@pages/admin/AdminPage"));
const LazyAssistantFormPage = lazy(
  () => import("@pages/admin/assistants/AssistantForm")
);
const LazyModelFormPage = lazy(() => import("@pages/admin/models/ModelForm"));
const LazyRoleFormPage = lazy(() => import("@pages/admin/roles/RoleForm"));
const LazyLayoutPage = lazy(() => import("@pages/layout/Layout"));
const LazyAssistantPage = lazy(() => import("@pages/chat/Assistant"));
const LazyNewLayoutPage = lazy(() => import("@pages/newlayout/NewLayout"));

interface AppProps {
  instance: PublicClientApplication;
}

initializeIcons();

const MainContent = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [roles, setRoles] = useState<string[]>([]);
  const ability = useContext(AbilityContext);
  const currentTheme = useAtomValue(currentThemeAtom);
  const [, setMsalInstance] = useAtom(msalInstanceAtom);
  const currentAssistant = useAtomValue(currentAssistantAtom);

  useEffect(() => {
    if (currentAssistant) {
      document.title = `Lis - ${currentAssistant.id}`
    } else {
      document.title = "Lis"
    }
  }, [currentAssistant]);

  useEffect(() => {
    setMsalInstance(instance);
  }, [instance, setMsalInstance]);

  useEffect(() => {
    if (activeAccount) {
      const newRoles = activeAccount.idTokenClaims?.roles;

      if (newRoles) {
        // Only update the state if the roles have changed
        if (JSON.stringify(newRoles) !== JSON.stringify(roles)) {
          setRoles(newRoles);
        }
      }
    } else {
      console.warn(
        "No active account... Application must reauthenticate to continue."
      );
    }
  }, [activeAccount, instance, setRoles]);

  useEffect(() => {
    if (roles.length > 0) {
      ability.update(defineAbilityFor(roles).rules);
      console.info(roles);
    }
  }, [roles]);

  return (
    <FluentProvider theme={webLightTheme} style={{ width: "100%" }}>
      {import.meta.env.DEV && (
        <Suspense fallback={<Loading />}>
          <LazyJotaiDevTools />
        </Suspense>
      )}
      <ThemeProvider theme={currentTheme}>
        <AuthenticatedTemplate>
          <AppStateProvider>
            <AbilityContext.Provider value={ability}>
            <Routes>
                {activeAccount && (
                  <>
                    <Route
                      path="/"
                      element={<Navigate to="/institucional" replace />}
                    />
                    <Route
                      path="/:assistantId"
                      element={
                        <Suspense fallback={<Loading />}>
                          <LazyNewLayoutPage />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/assistant-chat"
                      element={
                        <Suspense fallback={<Loading />}>
                          <Navigate to="/institucional" replace />
                        </Suspense>
                      }
                    >
                      <Route
                        path="/assistant-chat/:assistantId"
                        element={
                          <Suspense fallback={<Loading />}>
                            <LazyNewLayoutPage />
                          </Suspense>
                        }
                      />
                    </Route>
                    {ability.can("create", "Assistant") && (
                      <>
                        <Route
                          path="/admin"
                          element={
                            <Suspense fallback={<Loading />}>
                              {<LazyAdminPage />}
                            </Suspense>
                          }
                        />
                        <Route
                          path="/admin/assistants/form"
                          element={
                            <Suspense fallback={<Loading />}>
                              {<LazyAssistantFormPage />}
                            </Suspense>
                          }
                        />
                        <Route
                          path="/admin/assistants/form"
                          element={
                            <Suspense fallback={<Loading />}>
                              {<LazyAssistantFormPage />}
                            </Suspense>
                          }
                        />
                        <Route
                          path="/admin/models/form"
                          element={
                            <Suspense fallback={<Loading />}>
                              {<LazyModelFormPage />}
                            </Suspense>
                          }
                        />
                        <Route
                          path="/admin/roles/form"
                          element={
                            <Suspense fallback={<Loading />}>
                              {<LazyRoleFormPage />}
                            </Suspense>
                          }
                        />
                      </>
                    )}
                  </>
                )}
                <Route path="/teams-login" element={<LoginTeamsEnd />} />
                <Route path="/login" element={<Login />} />
                {!activeAccount && <Route path="*" element={<Login />} />}
                {activeAccount && <Route path="*" element={<NoPage />} />}
              </Routes>
            </AbilityContext.Provider>
          </AppStateProvider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>
            <Route path="/teams-login" element={<LoginTeamsStart />} />
            <Route path="/teams-login-end" element={<LoginTeamsEnd />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </UnauthenticatedTemplate>
      </ThemeProvider>
    </FluentProvider>
  );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }: AppProps) => {
  return (
    <MsalProvider instance={instance}>
      <MainContent />
    </MsalProvider>
  );
};

export default App;
